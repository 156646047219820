
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { sha256, sha224 } from 'js-sha256';
import router from "@/router/clean";

export default defineComponent({
  name: "ConfirmPassword",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },



  setup(props) {

    const store = useStore();
    const emailMissingError = "";
    const token = ref(props.token);
    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: Yup.string().min(8).required().label("password"),

      //password must be equal to passwordConfirm
      passwordConfirmMatch: Yup
        .string()
        .test('match', 
            'passwords do not match', 
            function() { 
            return this.parent.password === this.parent.passwordConfirm; 
       }),

    });


    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");


      // dummy delay
      setTimeout(() => {
        // Send login request

        ApiService.postNoAxios('/auth/confirmPassword', {token: token.value, password: values.password})
        .then(response => {
          console.log(response);
          Swal.fire({
            title: "Sucesso",
            text: "Password alterada com sucesso",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            router.push("/sign-in");
          })
        }).catch(error => {
          console.log(error);
          //if error code = 401 then token is invalid
          if(error.response.status == 401){
            Swal.fire({
              title: "Erro",
              text: "Token já foi utilizado anteriormente",
              icon: "error",
              confirmButtonText: "Ok",
            }).then(() => {
              router.push("/sign-in");
            })
          }
          else{
            Swal.fire({
              title: "Erro",
              text: "Erro ao alterar password",
              icon: "error",
              confirmButtonText: "Ok",
            }).then(() => {
              router.push("/sign-in");
            })
          }
        })        

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      emailMissingError
    };
  },
});
